import React from "react";

const Home = React.lazy(() => import("./views/Home"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));
const ConfirmResetToken = React.lazy(() => import("./views/ConfirmResetToken"));
const Login = React.lazy(() => import("./views/Login"));
const Registration = React.lazy(() => import("./views/Registration"));
const Tour = React.lazy(() => import("./views/Tour"));
const ChangePassword = React.lazy(() => import("./views/ChangePassword"));
const ActivatePin = React.lazy(() => import("./views/ActivatePin"));

const routes: RoutesType[] = [
  {
    name: "Home",
    layout: "/",
    path: "home",
    icon: "",
    component: <Home />,
  },
  {
    name: "Tour",
    layout: "/",
    path: "tour",
    icon: "",
    component: <Tour />,
  },
  {
    name: "Login",
    layout: "/",
    path: "login",
    icon: "",
    component: <Login />,
  },
  {
    name: "Forgot Password",
    layout: "/",
    path: "forgot-password",
    icon: "",
    component: <ForgotPassword />,
  },
  {
    name: "Forgot Password",
    layout: "/",
    path: "forgot-password",
    icon: "",
    component: <ForgotPassword />,
  },
  {
    name: "Confirm Reset Password",
    layout: "/",
    path: "confirm-token/:username",
    icon: "",
    component: <ConfirmResetToken />,
  },
  {
    name: "Change Password",
    layout: "/",
    path: "change-password/:username",
    icon: "",
    component: <ChangePassword />,
  },
  {
    name: "Registration",
    layout: "/",
    path: "registration",
    icon: "",
    component: <Registration />,
  },
  {
    name: "Activate Pin",
    layout: "/",
    path: "activate-pin",
    icon: "",
    component: <ActivatePin />,
  },
];

export default routes;
