import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  getReferralDetails,
  getReferralHistory,
  updateReferralCode,
} from "../api/referral.api";
import { notify } from "core/helpers/notify";

type State = {
  isLoading: boolean;
  referralInfo: ReferralInfo | null;
  referralHistory: ReferralHistory[];
  getReferralDetails: () => Promise<void>;
  getReferralHistory: () => Promise<void>;
  updateReferralCode: (code: string) => Promise<UIResponse>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  referralInfo: null,
  referralHistory: [],
};

const useReferralStore = create<State>()(
  devtools(
    persist(
      (set, get): State => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        getReferralDetails: async () => {
          set({ isLoading: true });

          var res = await getReferralDetails();

          if (res?.isSuccessful) {
            set({ referralInfo: res?.data?.data });
          } else {
            set({ referralInfo: null });
          }

          set({ isLoading: false });
        },
        getReferralHistory: async () => {
          set({ isLoading: true });

          var res = await getReferralHistory();

          if (res?.isSuccessful) {
            set({ referralHistory: res?.data?.data });
          } else {
            set({ referralHistory: [] });
          }

          set({ isLoading: false });
        },
        updateReferralCode: async (code) => {
          set({ isLoading: true });

          var res = await updateReferralCode(code);

          if (res?.data?.status) {
            set((state) => ({
              referralInfo: {
                ...state.referralInfo!,
                referralCode: res?.data?.data?.referralCode,
              },
            }));
          }

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
      }),
      {
        name: "referralStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useReferralStore;
