import React from "react";

const MarketplaceDashboard = React.lazy(() => import("./MarketplaceDashboard"));
const FastFood = React.lazy(() => import("./fastFood/FastFood"));
const FastFoodVendor = React.lazy(() => import("./fastFood/FastFoodVendor"));
const Events = React.lazy(() => import("./event/Events"));
const EventDetails = React.lazy(() => import("./event/EventDetails"));
const EventBooking = React.lazy(() => import("./event/EventBooking"));
const FastFoodCart = React.lazy(() => import("./fastFood/FastFoodCart"));

const marketplaceRoutes: RoutesType[] = [
  {
    name: "Fast Food",
    layout: "/portal",
    path: "marketplace/fast-food/vendor/:vendorId",
    icon: "",
    component: <FastFoodVendor />,
  },
  {
    name: "Fast Food Cart",
    layout: "/portal",
    path: "marketplace/fast-food/cart",
    icon: "",
    component: <FastFoodCart />,
  },
  {
    name: "Fast Food",
    layout: "/portal",
    path: "marketplace/fast-food",
    icon: "",
    component: <FastFood />,
  },
  {
    name: "Event Booking",
    layout: "/portal",
    path: "marketplace/events/:eventId/booking",
    icon: "",
    component: <EventBooking />,
  },
  {
    name: "Event Details",
    layout: "/portal",
    path: "marketplace/events/:eventId",
    icon: "",
    component: <EventDetails />,
  },
  {
    name: "Events",
    layout: "/portal",
    path: "marketplace/events",
    icon: "",
    component: <Events />,
  },
  {
    name: "Marketplace",
    layout: "/portal",
    path: "marketplace",
    icon: "",
    component: <MarketplaceDashboard />,
  },
];

export default marketplaceRoutes;
