import ham from "assets/img/hamburgerBlack.svg";
import logo from "assets/img/logo.svg";
import cartIcon from "assets/img/shoppingCart.svg";
import backIcon from "assets/img/arrowLeft.svg";
import useUserStore from "core/services/stores/useUserStore";
import { useNavigate } from "react-router-dom";
import { notify } from "core/helpers/notify";
import { cx } from "core/helpers/generalHelpers";
import SelectField from "../formfields/SelectField";
import locationIcon from "assets/img/locationBlack.svg";
import useLocationStore from "core/services/stores/useLocationStore";
import { useEffect, useState } from "react";

interface Props {
  title?: string;
  navigateBack?: boolean;
  showLogo?: boolean;
  showCart?: boolean;
  backUrl?: string;
  cartUrl?: string;
  cartCount?: number;
  address?: string;
  showTitleLocation?: boolean;
  backAction?: any;
  useBackAction?: boolean;
}

const HeaderPlain = ({
  title = "",
  navigateBack = false,
  showLogo = true,
  showTitleLocation = true,
  showCart = false,
  backUrl = "",
  cartUrl = "",
  cartCount = 0,
  address = "",
  backAction = () => {},
  useBackAction = false,
}: Props) => {
  const navigate = useNavigate();

  const user = useUserStore((store) => store.user);
  const toggleNavAction = useUserStore((store) => store.toggleSideNav);
  const locations = useLocationStore((store) => store.locations);
  const [selectedLocation, setSelectedLocation] = useState(user?.location);

  const getLocationsAction = useLocationStore((store) => store.getLocations);
  const switchLocationAction = useUserStore((store) => store.switchLocation);

  const switchLocation = async (e: any) => {
    var val = e?.target?.value;

    if (val?.length > 1) {
      setSelectedLocation(val);
      await switchLocationAction(val!);
    } else {
      notify({
        type: "warning",
        message: "Please switch to a valid location",
      });
    }
  };

  const returnBack = () => {
    if (useBackAction) {
      backAction();
    } else {
      if (backUrl?.length > 0) {
        navigate(backUrl);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    locations == null || (locations?.length < 1 && getLocationsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="py-4">
        <nav
          className={`mx-auto flex w-11/12 items-center justify-between gap-3 md:w-4/5`}
        >
          <div className="">
            {navigateBack ? (
              <button
                className="rounded-[8px] border-[0.3px] border-[#e8e8e8] bg-white p-1"
                onClick={() => returnBack()}
              >
                <img
                  src={backIcon}
                  className="h-[28px] w-[28px] text-black
                  hover:cursor-pointer"
                  alt="back"
                />
              </button>
            ) : (
              <img
                src={ham}
                onClick={() => {
                  toggleNavAction(true);
                }}
                className="toggleBar text-black hover:cursor-pointer"
                alt="open side bar"
              />
            )}
          </div>

          <div className="">
            {showTitleLocation &&
              (title?.length < 1 ? (
                <div
                  className={cx(
                    "flex items-center justify-between gap-1 overflow-hidden",
                    address?.length < 1
                      ? ""
                      : "rounded-full !bg-black px-5 py-1 !text-white opacity-[.7]",
                  )}
                >
                  <img
                    className="mt-2 h-[20px] w-[20px]"
                    src={locationIcon}
                    alt="change location"
                  />

                  {address?.length < 1 ? (
                    <>
                      <SelectField
                        margin=""
                        label=""
                        name="location"
                        boxStyle="!h-[40px] !px-1 !border-none"
                        selectStyle={cx("!bg-transparent")}
                        value={selectedLocation}
                        defaultName="Switch Location"
                        defaultValue=""
                        options={
                          locations?.length > 0
                            ? [
                                ...locations?.map((loc: CampusLocation) => ({
                                  name: loc?.name,
                                  value: loc?.name,
                                })),
                              ]
                            : []
                        }
                        onChange={(e: any) => {
                          switchLocation(e);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p className="h-[25px] px-1 py-1">{address}</p>
                    </>
                  )}
                </div>
              ) : (
                <p className="text-[24px] capitalize">{title}</p>
              ))}
          </div>

          {showLogo ? (
            <img className="h-[40px] w-[40px]" src={logo} alt="Campusrunz" />
          ) : showCart ? (
            <button
              className={cx(
                "relative rounded-[8px] border-[0.3px] border-[#e8e8e8] bg-white p-1",
                cartCount > 0
                  ? ""
                  : "!cursor-not-allowed hover:cursor-not-allowed",
              )}
              onClick={() => {
                if (cartCount > 0) {
                  navigate(cartUrl);
                } else {
                  notify({
                    type: "warning",
                    message: "Please add items to cart",
                  });
                }
              }}
            >
              <img
                src={cartIcon}
                className="h-[28px] w-[28px] text-black"
                alt="shopping cart"
              />
              {cartCount > 0 && (
                <span className="absolute -right-1 -top-2 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-red-600 text-[14px] font-bold text-white">
                  {cartCount}
                </span>
              )}
            </button>
          ) : (
            <div></div>
          )}
        </nav>
      </section>
    </>
  );
};

export default HeaderPlain;
