import { cx } from "core/helpers/generalHelpers";
import React from "react";
import deleteIcon from "assets/img/close.svg";

interface ModalProps {
  onClose: () => void;
  children?: React.ReactNode;
  header?: string;
  instruction?: string;
  boxStyle?: string;
  bodyStyle?: string;
}

function Modal({
  onClose,
  children = <div />,
  header = "",
  instruction = "",
  boxStyle = "",
  bodyStyle = "",
}: ModalProps) {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center overflow-auto bg-[#6C18A4BF] bg-opacity-10 ${boxStyle}`}
      style={{ minHeight: "calc(100vh - 72px)", zIndex: 2500 }}
    >
      <div
        className={`mx-auto w-full max-w-md rounded-lg bg-white p-8 ${bodyStyle}`}
      >
        <div className="flex items-center justify-between">
          <div>
            {header && (
              <p className="text-brand text-xl font-semibold">{header}</p>
            )}
            {instruction && (
              <p className="text-gray-700 mt-1 text-base">{instruction}</p>
            )}
          </div>

          <button
            onClick={onClose}
            aria-label="Close"
            className={cx(
              "rounded-full bg-[#F5F4F4] px-[8px] py-[8px] disabled:cursor-not-allowed",
            )}
          >
            <img src={deleteIcon} alt="close modal" />
          </button>
        </div>
        <div className="mt-4">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
