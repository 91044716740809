import { btn } from "core/consts/styling";
import likeIcon from "assets/img/purpleHeart.svg";
import { cx } from "core/helpers/generalHelpers";

interface Props {
  callAction?: any;
  title: string;
  likeAction?: any;
  showLike?: boolean;
  disableBtn?: boolean;
  cartCount?: number;
  children?: React.ReactNode;
}

const FooterBtn = ({
  callAction = () => {},
  showLike = true,
  likeAction = () => {},
  cartCount = 0,
  disableBtn = false,
  children = <></>,
  title,
}: Props) => {
  return (
    <>
      <nav
        style={{
          zIndex: 2000,
        }}
        className="fixed bottom-0 left-0 w-full bg-white"
      >
        <section className="mx-auto flex w-11/12 flex-col items-center justify-start overflow-hidden md:w-4/5">
          <div className="mb-5 flex w-full items-center justify-between gap-3">
            {showLike && (
              <button
                onClick={() => likeAction()}
                className={cx(
                  btn,
                  "w-1/4 !rounded-full border-[0.5px] border-purple !px-[20px] !py-[10px]",
                )}
              >
                <img
                  src={likeIcon}
                  alt="like business"
                  className="h-[20px] w-[20px]"
                />
              </button>
            )}
            <div className={cx(showLike ? "w-3/4" : "w-full")}>
              <button
                onClick={() => callAction()}
                disabled={disableBtn}
                className={cx(
                  btn,
                  "w-full !rounded-full border-[0.5px] border-purple !bg-purple !px-[20px] !py-[10px] !text-[14px] !text-white disabled:cursor-not-allowed",
                )}
              >
                {title}

                {cartCount > 0 && (
                  <span className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-red-600 text-[14px] font-bold text-white">
                    {cartCount}
                  </span>
                )}
              </button>
              {children}
            </div>
          </div>
        </section>
      </nav>
    </>
  );
};

export default FooterBtn;
