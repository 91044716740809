import SelectField from "core/components/formfields/SelectField";
import FooterBtn from "core/components/layouts/FooterBtn";
import HeaderPlain from "core/components/layouts/HeaderPlain";
import { addMetaData } from "core/helpers/seoHelpers";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import seatIcon from "assets/img/seat.svg";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { notify } from "core/helpers/notify";
import { cx } from "core/helpers/generalHelpers";
import CashbackField from "core/components/CashbackField";
import useUserStore from "core/services/stores/useUserStore";
import useWalletStore from "core/services/stores/useWalletStore";
import { OrderSummary } from "modules/portal/views/partials/OrderSummary";
import { PAYMENT_OPTIONS } from "core/consts/systemConst";

const JoinTripInTransit = () => {
  const navigate = useNavigate();
  const { busStopId } = useParams();
  const user = useUserStore((store) => store.user);
  const hasBvn = user?.profileType === 1;
  const cashbackBalance = useWalletStore((store) => store.cashbackBalance);

  const trip = useTransportationStore((store) => store.selectedTripInTransit);
  const joinOngoingTripAction = useTransportationStore(
    (store) => store.joinOngoingTrip,
  );
  const getWalletBalanceAction = useWalletStore(
    (store) => store.getWalletBalance,
  );
  const setPathAction = useWalletStore((store) => store.setSourcePath);

  const [destinationBusStop, setDestinationBusStop] = useState("");
  const [pickUpBusStop, setPickUpBusStop] = useState(busStopId!);
  const [desiredSeats, setDesiredSeats] = useState<number>(0);
  const [paymentOptions, setPaymentOptions] = useState(1);
  const [useCashback, setUseCashback] = useState("false");
  const setTransferRequestAction = useWalletStore(
    (store) => store.setTransferRequest,
  );

  var totalCost =
    useCashback === "true"
      ? +trip?.fare! + trip?.convenienceFee! - +cashbackBalance
      : +trip?.fare! + trip?.convenienceFee!;

  const [errors, setErrors] = useState({
    destinationBusStop: [],
    pickUpBusStop: [],
    PaymentOption: [],
  });

  const onErrorChange = (name: string, value: string) => {
    setErrors((state: any) => ({
      ...state,
      [name]: [value],
    }));
  };

  const backAction = () => {
    navigate("/portal/transportation/share-bus/trips-in-transit");
  };

  const addRemoveSeat = (seatNumber: number) => {
    if (desiredSeats !== seatNumber) {
      setDesiredSeats(seatNumber);
    } else {
      setDesiredSeats(0);
    }
  };

  const validateTrip = (
    pickup: string,
    destination: string,
    seats: number,
    paymentOption: number,
  ) => {
    var isValid = true;

    if (destination?.length < 1) {
      isValid = false;
      onErrorChange(
        "destinationBusStop",
        "Please choose your desired destination bus stop.",
      );
    }

    if (pickup?.length < 1) {
      isValid = false;
      onErrorChange("pickUpBusStop", "Please choose your pick up bus stop.");
    }

    if (seats < 1) {
      isValid = false;
      notify({
        message: "Please select at least a seat",
        type: "warning",
      });
    }

    if (!hasBvn && ![1, 2].includes(paymentOption)) {
      onErrorChange("PaymentOption", "Please choose a payment option");
      isValid = false;
    }

    return isValid;
  };

  const joinTrip = async () => {
    if (
      validateTrip(
        pickUpBusStop,
        destinationBusStop,
        desiredSeats,
        paymentOptions,
      )
    ) {
      var request: OngoingTripSchedule = {
        convenienceFee: trip?.convenienceFee!,
        preferredSeat: desiredSeats,
        fare: trip?.fare!,
        paymentOption: +paymentOptions,
        tripId: trip?.id!,
        useCashBackBalance: useCashback === "true",
        destinationBusStop,
        pickUpBusStop,
      };

      var res = await joinOngoingTripAction(request, hasBvn);

      if (res?.data?.status) {
        var selectedPaymentOption = paymentOptions;
        setPaymentOptions(1);

        if (hasBvn) {
          getWalletBalanceAction();
          navigate("/portal/transportation/share-bus");
        } else {
          if (res?.data?.data !== null) {
            if (selectedPaymentOption === 1) {
              if (res?.data?.data?.redirectUrl?.length > 0) {
                window.location.replace(res?.data?.data?.redirectUrl);
              } else {
                notify({
                  type: "warning",
                  message:
                    "Payment could not be processed at the moment, please select a different option or try again later",
                });

                navigate("/portal/transportation/share-bus");
              }
            } else {
              setTransferRequestAction({ ...res?.data?.data });
              navigate("/portal/bank-transaction");
            }
          } else {
            getWalletBalanceAction();
            navigate("/portal/transportation/share-bus");
          }
        }
      }
    } else {
      notify({
        message: "Please pass all required information",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    if (busStopId == null || busStopId?.length < 1) navigate(-1);
    setPathAction("/portal/transportation/share-bus");
    getWalletBalanceAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb-[100px]">
      {addMetaData({
        title: "",
        description: "",
      })}

      <HeaderPlain
        title="Join trip"
        showTitleLocation
        navigateBack
        showLogo={false}
        useBackAction={true}
        backAction={() => backAction()}
      />

      <main className="mx-auto mb-5 mt-8 w-11/12 md:w-4/5">
        <SelectField
          margin="mb-8"
          label="Select Pick Up"
          value={pickUpBusStop}
          defaultName="Select pick up point"
          defaultValue=""
          options={
            trip?.busInfo?.routeInfo!?.length > 0
              ? [
                  ...trip?.busInfo?.routeInfo!?.map((path) => ({
                    name: path?.busStop,
                    value: path?.id,
                  })),
                ]
              : []
          }
          onChange={(e: any) => {
            setPickUpBusStop(e?.target?.value);
          }}
          error={errors?.pickUpBusStop[0]}
          onBlur={() => onErrorChange("pickUpBusStop", "")}
        />

        <SelectField
          margin="mb-5"
          label="Select Destination"
          value={destinationBusStop}
          defaultName="Select destination point"
          defaultValue=""
          options={
            trip?.busInfo?.routeInfo!?.length > 0
              ? [
                  ...trip?.busInfo?.routeInfo!?.map((path) => ({
                    name: path?.busStop,
                    value: path?.id,
                  })),
                ]
              : []
          }
          onChange={(e: any) => {
            setDestinationBusStop(e?.target?.value);
          }}
          error={errors?.destinationBusStop[0]}
          onBlur={() => onErrorChange("destinationBusStop", "")}
        />

        <p className="mb-8 bg-[#F4FCED] p-5">
          Click on the dropdown the final destination, if your destination is
          before{" "}
          {
            trip?.busInfo.routeInfo[trip?.busInfo?.routeInfo?.length - 1]
              ?.busStop
          }
          , must be on the same route
        </p>

        <section>
          <p className="mb-3 font-[500]">Available seats</p>

          <div className="grid grid-cols-3 items-center gap-5 md:grid-cols-4 lg:grid-cols-5">
            {Array.from(
              { length: trip?.busInfo?.totalSeats! },
              (_, index) => index + 1,
            )?.map((seat, i) => (
              <button
                onClick={() => addRemoveSeat(seat)}
                disabled={!trip?.busInfo?.availableSeats?.includes(seat)}
                className={cx(
                  "min-h-[80px] rounded-[8px] border-[0.5px] border-purple p-2 text-center",
                  trip?.busInfo?.availableSeats?.includes(seat)
                    ? "hover:cursor-pointer hover:bg-yellow-500"
                    : "!bg-purple !text-white disabled:cursor-not-allowed",
                  desiredSeats === seat ? "!bg-yellow-500" : "",
                )}
              >
                <img
                  src={seatIcon}
                  alt={`seat #${i + 1}`}
                  className="inline-block"
                />
                <p className="my-2">Seat #</p>
                <p>{seat}</p>
              </button>
            ))}
          </div>

          {!hasBvn && (
            <SelectField
              margin="my-10"
              label="Select payment option"
              name="paymentOption"
              isRequired
              defaultName="Select option"
              defaultValue=""
              value={paymentOptions}
              options={[...PAYMENT_OPTIONS?.map((option) => option)]}
              onChange={(e: any) => setPaymentOptions(+e?.target?.value)}
              error={errors?.PaymentOption[0]}
              onBlur={() => onErrorChange("PaymentOption", "")}
            />
          )}
        </section>

        <main className="mx-auto mt-10 w-11/12 border-y-[.5px] border-y-[#D4D2D5] py-5 md:w-4/5">
          <OrderSummary title="Cost per trip" amount={trip?.fare!} />
          <OrderSummary
            title="Convenience fee"
            amount={trip?.convenienceFee!}
          />
          {useCashback === "true" && (
            <OrderSummary
              title="Cashback balance"
              isNegative
              amount={+cashbackBalance}
            />
          )}
          <OrderSummary
            title="Total amount"
            styling="mb-0 !font-semibold"
            amount={totalCost < 0 ? 0 : totalCost}
          />
        </main>

        <CashbackField
          value={useCashback}
          setValue={setUseCashback}
          cashbackBalance={cashbackBalance}
        />

        <FooterBtn
          title="Make payment"
          showLike={false}
          callAction={() => {
            joinTrip();
          }}
        />
      </main>
    </div>
  );
};

export default JoinTripInTransit;
