import { apiCall } from "./apiCall";

export const addReview = (review: Review) => {
  return apiCall({
    endpoint: "/rating/addRatingAndReview",
    method: "POST",
    auth: true,
    body: {
      ...review,
    },
  });
};
