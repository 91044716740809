export const BUSINESS_MODULES_TYPES = {
  super_admin: "super_admin",
  accommodation: "accommodation",
  marketplace: "marketplace",
  laundry: "laundry",
  foodstuff: "foodstuff",
  engineering: "engineering",
  transportation: "transportation",
};

export const BUSINESS_MODULES = [
  {
    name: "Accommodation",
    value: "accommodation",
  },
  {
    name: "Market Place",
    value: "marketplace",
  },
  {
    name: "Laundry",
    value: "laundry",
  },
  {
    name: "Food Stuff",
    value: "foodstuff",
  },
  {
    name: "Maintenance & Engineering Services",
    value: "engineering",
  },
  {
    name: "Transportation",
    value: "transportation",
  },
];

export const REWARD_TYPES = {
  referrerbonus: "referrerbonus",
  referreduserdiscount: "referreduserdiscount",
};

export const SECURITY_QUESTIONS = [
  "Name of School",
  "Mothers maiden name",
  "Primary school name",
  "Name of Son",
  "House number and street name",
  "Pets name",
  "Name of your favourite lecturer",
  "Best Course",
  "Your Nickname",
  "Your Favourite Colour",
];

export const REASONS_FOR_DELETING_ACCOUNT = [
  "I have issues with the platform",
  "I want to erase my data",
  "I am not using the app",
  "Graduated from school",
  "Others",
];

export const ID_TYPES = [
  {
    name: "National ID",
    value: "National ID",
  },
  {
    name: "Permanent Voters ID",
    value: "Permanent Voters ID",
  },
  {
    name: "Drivers license",
    value: "Drivers license",
  },
  {
    name: "International Passport",
    value: "International Passport",
  },
];

export const GENDERS = [
  { name: "Female", value: "Female" },
  { name: "Male", value: "Male" },
];

export const MARKETPLACE_CATEGORY = [
  {
    name: "Fast food",
    value: "fast_food",
  },
  {
    name: "Gas refill",
    value: "gas_refill",
  },
  {
    name: "Event ticket",
    value: "event_ticket",
  },
  {
    name: "Hotel",
    value: "hotel",
  },
  {
    name: "Media and Production",
    value: "media_production",
  },
];

export const MARKETPLACE_CATEGORY_TYPES = {
  Fast_Food: "fast_food",
  Gas_Refill: "gas_refill",
  Event_Ticket: "event_ticket",
  Media_Production: "media_production",
  Hotel: "hotel",
};

export const ORDER_STATUS = {
  pending: "pending",
  processing: "processing",
  cancelled: "cancelled",
  delivered: "delivered",
  accepted: "accepted",
  successful: "successful",
  failed: "failed",
  awaiting_payment: "awaiting_payment",
};

export const PROCESS_ORDER = {
  accept: "accept",
  cancel: "cancel",
  delivered: "delivered",
};

export const PROCESS_INSPECTION_REQUEST = {
  accepted: "accepted",
  fulfilled: "fulfilled",
  declined: "declined",
  pending: "pending",
};

export const TRANSPORTATION_AGENTS = [
  {
    name: "Delivery Agent",
    value: 1,
  },
  {
    name: "Driver",
    value: 4,
  },
];

export const TRANSPORTATION_AGENTS_TYPE = {
  deliveryAgent: 1,
  driver: 4,
};

export const NotificationType: any = {
  "1": "Cancelled",
  "2": "Delivered",
  "3": "Assigned",
  "4": "Shipped",
  "5": "Weight Update",
  "6": "Received",
  "0": "",
  "": "",
};

export const ProfileType = [
  {
    name: "With BVN",
    value: 1,
  },
  {
    name: "Without BVN",
    value: 2,
  },
];

export const PAYMENT_OPTIONS = [
  {
    name: "Debit Card",
    value: 1,
  },
  {
    name: "Bank Transfer",
    value: 2,
  },
];

export const ISSUES_CATEGORY = [
  "Feedback & enquiry",
  "Login",
  "Onboarding",
  "Wallet funding",
];

export const TransactionType = {
  Credit: "Credit",
  Debit: "Debit",
};

export const GatewayFeedbackOption = {
  Order: 1,
  Transportation: 2,
  Accommodation: 3,
};

export const FEE_TYPES = {
  BankTransfer: "bank_transfer",
  Card: "card",
  MobileMoney: "mobilemoney",
  AchPayment: "arch-payment",
  DebitNgAccount: "debit-ng-account",
};

export const RIDE_STATUS = {
  UNASSIGNED: "unassigned ",
  ASSIGENED: "assigned",
  INTRANSIT: "intransit",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  CANCELED: "canceled",
} as const;
