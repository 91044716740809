import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  confirmResetToken,
  generateResetToken,
  registerNewUser,
  resetPassword,
  signIn,
  upgradeAccount,
  uploadUserFile,
  validateOTP,
  verifyPhoneNumber,
} from "../api/auth.api";
import { notify } from "core/helpers/notify";
import {
  changePassword,
  changePin,
  changeSecurityQuestion,
  deleteAccount,
  getForgotPinOtp,
  getProfileDetails,
  resetPin,
  switchLocation,
  validateForgotPinOtp,
} from "../api/profile.api";
import { reportIssue } from "../api/support.api.";

type UserState = {
  isLoading: boolean;
  user: User | null;
  token: string | null;
  isSideNavOpen: boolean;
  refreshToken: string | null;
  dobGenderEmail: UserDobGenderEmail | null;
  signIn: (username: string, password: string) => Promise<UIResponse>;
  verifyPhoneNumber: (phoneNumber: string) => Promise<UIResponse>;
  validateOTP: (phoneNumber: string, token: string) => Promise<UIResponse>;
  registerUser: (user: NewUser) => Promise<UIResponse>;
  uploadImage: (file: any) => Promise<UIResponse>;
  toggleSideNav: (isOpen: boolean) => void;
  getProfileDetails: () => Promise<void>;
  generateResetToken: (username: string) => Promise<UIResponse>;
  confirmResetToken: (email: string, token: string) => Promise<UIResponse>;
  changePassword: (data: ChangePassword) => Promise<UIResponse>;
  changePin: (data: ChangePin) => Promise<UIResponse>;
  changeSecurityQuestion: (data: SecurityData) => Promise<UIResponse>;
  deleteAccount: (reason: string, suggestion: string) => Promise<UIResponse>;
  getForgotPinOtp: () => Promise<UIResponse>;
  validateForgotPinOtp: (otp: string) => Promise<UIResponse>;
  resetPin: (newPin: string, confirmPin: string) => Promise<UIResponse>;
  reportIssue: (issue: Issue) => Promise<UIResponse>;
  switchLocation: (location: string) => Promise<UIResponse>;
  upgradeAccount: (data: NationalId) => Promise<UIResponse>;
  resetPassword: (
    username: string,
    password: string,
    confirmPassword: string,
  ) => Promise<UIResponse>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  isSideNavOpen: false,
  user: null,
  dobGenderEmail: null,
  token: "",
  refreshToken: "",
};

const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set, get): UserState => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        toggleSideNav: (isOpen) => {
          set({ isSideNavOpen: isOpen });
        },
        signIn: async (username, password) => {
          set({ isLoading: true });

          var res = await signIn(username, password);

          if (res?.isSuccessful) {
            set({
              user: res?.data?.data,
              token: res?.data?.tokenCred?.token,
              refreshToken: res?.data?.tokenCred?.refreshToken,
            });
          }

          notify({
            message: res?.isSuccessful
              ? `Welcome back ${res?.data?.data?.firstName}`
              : res?.data?.error?.message ??
                "Please pass in the required information",
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        switchLocation: async (location) => {
          set({ isLoading: true });

          var res = await switchLocation(location);

          if (res?.isSuccessful) {
            set((state: any) => ({
              user: {
                ...state.user,
                location: location,
              },
            }));
          }

          notify({
            message: res?.isSuccessful
              ? `You have successfully switched your location to ${location}`
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        verifyPhoneNumber: async (phoneNumber) => {
          set({ isLoading: true });

          var res = await verifyPhoneNumber(phoneNumber);

          notify({
            message: res?.isSuccessful
              ? "An otp has been sent to you for final verification"
              : res?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        validateOTP: async (phoneNumber, token) => {
          set({ isLoading: true });

          var res = await validateOTP(phoneNumber, token);

          notify({
            message: res?.isSuccessful
              ? "Phone number verified successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        registerUser: async (user) => {
          set({ isLoading: true });

          var res = await registerNewUser(user);

          if (res?.isSuccessful) {
            set({ user: res?.data?.data });
          }

          notify({
            message: res?.isSuccessful
              ? "You have been registered successfully"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        uploadImage: async (file) => {
          set({ isLoading: true });

          var userId = get().user?.userId;
          var res = await uploadUserFile(userId!, file, "uploadImage");

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getProfileDetails: async () => {
          set({ isLoading: true });

          var res = await getProfileDetails();

          if (res?.isSuccessful) {
            set({ dobGenderEmail: res?.data });
          } else {
            set({ dobGenderEmail: null });
          }

          set({ isLoading: false });
        },
        generateResetToken: async (username) => {
          set({ isLoading: true });

          var res = await generateResetToken(username);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        confirmResetToken: async (email, token) => {
          set({ isLoading: true });

          var res = await confirmResetToken(email, token);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        resetPassword: async (username, password, confirmPassword) => {
          set({ isLoading: true });

          var res = await resetPassword(username, password, confirmPassword);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        changePassword: async (data) => {
          set({ isLoading: true });

          var res = await changePassword(data);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        changePin: async (data) => {
          set({ isLoading: true });

          var res = await changePin(data);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        changeSecurityQuestion: async (data) => {
          set({ isLoading: true });

          var res = await changeSecurityQuestion(data);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getForgotPinOtp: async () => {
          set({ isLoading: true });

          var res = await getForgotPinOtp();

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        validateForgotPinOtp: async (otp) => {
          set({ isLoading: true });

          var res = await validateForgotPinOtp(otp);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        resetPin: async (newPin, confirmPin) => {
          set({ isLoading: true });

          var res = await resetPin(newPin, confirmPin);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        deleteAccount: async (reason, suggestion) => {
          set({ isLoading: true });

          var res = await deleteAccount(reason, suggestion);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        reportIssue: async (issue) => {
          set({ isLoading: true });

          var res = await reportIssue(issue);

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        upgradeAccount: async (data) => {
          set({ isLoading: true });

          var res = await upgradeAccount(data);

          if (res?.isSuccessful) {
          }

          notify({
            message: res?.isSuccessful
              ? res?.data?.message
              : res?.data?.error?.message,
            type: res?.data?.status ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
      }),
      {
        name: "userStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useUserStore;
