import { apiCall } from "./apiCall";

export const getLocations = () => {
  return apiCall({
    endpoint: "/location",
    method: "GET",
  });
};

export const getDeliveryArea = (
  query: DeliveryAreaQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apiCall({
    endpoint: "/deliveryArea",
    method: "GET",
    pQuery: { ...query },
    auth: true,
  });
};
