import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  deleteNotification,
  getAllNotifications,
  getAllOrder,
  getNotificationById,
  getOrderById,
  markAllNotificationAsRead,
  updateNotificationAsRead,
} from "../api/order.api";
import { notify } from "core/helpers/notify";

type State = {
  isLoading: boolean;
  orderList: OrderList;
  selectedOrder: Order | null;
  notificationList: NotificationList;
  selectedNotification: OrderNotification | null;
  getOrders: (query: OrderQuery) => Promise<void>;
  getOrderById: (orderId: string) => Promise<void>;
  getNotifications: (query: NotificationQuery) => Promise<void>;
  getNotificationById: (id: string) => Promise<void>;
  deleteNotification: (id: string) => Promise<UIResponse>;
  markAllAsRead: () => Promise<UIResponse>;
  updateToRead: (id: string) => Promise<UIResponse>;
  resetOrderList: () => void;
  resetSelectedOrder: () => void;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  orderList: {
    orders: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  selectedOrder: null,
  notificationList: {
    data: [],
    pageNumber: 1,
    pageSize: 15,
    totalCount: 1,
    totalPage: 1,
  },
  selectedNotification: null,
};

const useOrderState = create<State>()(
  devtools(
    persist(
      (set, get): State => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        resetOrderList: () => {
          set({
            orderList: {
              orders: [],
              pageNumber: 1,
              pageSize: 15,
              totalCount: 1,
              totalPage: 1,
            },
          });
        },
        resetSelectedOrder: () => {
          set({ selectedOrder: null });
        },
        getOrders: async (query) => {
          set({ isLoading: true });

          var res = await getAllOrder(query);

          if (res?.isSuccessful) {
            const { pageNumber, pageSize, totalCount, orders, totalPage } =
              res?.data?.data;

            const fetchedOrders =
              orders != null && orders?.length > 0 ? orders : [];

            set((state) => ({
              orderList: {
                orders:
                  query?.pageNumber == 1
                    ? [...fetchedOrders]
                    : [...state.orderList.orders, ...fetchedOrders],
                pageNumber,
                pageSize,
                totalCount,
                totalPage,
              },
            }));
          }

          set({ isLoading: false });
        },
        getOrderById: async (orderId) => {
          set({ isLoading: true });

          var res = await getOrderById(orderId);

          if (res?.isSuccessful) {
            set({ selectedOrder: res?.data?.data });
          } else {
            set({ selectedOrder: null });
          }

          set({ isLoading: false });
        },
        getNotifications: async (query) => {
          set({ isLoading: true });

          var res = await getAllNotifications(query);

          if (res?.isSuccessful) {
            const { pageNumber, pageSize, totalCount, data, totalPage } =
              res?.data;

            const fetchedNotifications =
              data != null && data?.length > 0 ? data : [];

            set((state) => ({
              notificationList: {
                data:
                  query?.pageNumber == 1
                    ? [...fetchedNotifications]
                    : [...state.notificationList.data, ...fetchedNotifications],
                pageNumber,
                pageSize,
                totalCount,
                totalPage,
              },
            }));
          }

          set({ isLoading: false });
        },
        getNotificationById: async (id) => {
          set({ isLoading: true });

          var res = await getNotificationById(id);

          if (res?.isSuccessful) {
            set({ selectedNotification: res?.data?.data });
          } else {
            set({ selectedNotification: null });
          }

          set({ isLoading: false });
        },
        deleteNotification: async (id) => {
          set({ isLoading: true });

          var res = await deleteNotification(id);

          if (res?.isSuccessful) {
            set((state) => ({
              notificationList: {
                ...state.notificationList,
                data: state.notificationList?.data?.filter(
                  (notice) => notice?.id !== id,
                ),
              },
            }));
          }

          notify({
            message: res?.isSuccessful
              ? "Notification has been deleted"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        markAllAsRead: async () => {
          set({ isLoading: true });

          var res = await markAllNotificationAsRead();

          if (res?.isSuccessful) {
            set((state) => ({
              notificationList: {
                ...state.notificationList,
                data: state.notificationList?.data?.map((notice) => ({
                  ...notice,
                  read: true,
                })),
              },
            }));
          }

          notify({
            message: res?.isSuccessful
              ? "All notification has been marked as read"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        updateToRead: async (id) => {
          set({ isLoading: true });

          var res = await updateNotificationAsRead(id);

          if (res?.isSuccessful) {
            set((state) => ({
              notificationList: {
                ...state.notificationList,
                data: state.notificationList?.data?.map((notice) =>
                  notice.id === id
                    ? {
                        ...notice,
                        read: true,
                      }
                    : notice,
                ),
              },
            }));
          }

          /*
          notify({
            message: res?.isSuccessful
              ? "Notification has been marked as read"
              : res?.data?.error?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });*/

          set({ isLoading: false });
          return res;
        },
      }),
      {
        name: "orderState",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useOrderState;
