import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { notify } from "core/helpers/notify";

import { addReview } from "../api/rating.api";

type State = {
  isLoading: boolean;
  addReview: (review: Review) => Promise<UIResponse>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
};

const useReviewStore = create<State>()(
  devtools(
    persist(
      (set, get): State => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        addReview: async (review) => {
          set({ isLoading: true });

          var res = await addReview(review);

          notify({
            message: res?.isSuccessful
              ? "Your review has been added"
              : res?.data?.error?.message ?? res?.data?.message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
      }),
      {
        name: "reviewStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useReviewStore;
