import {
  GatewayFeedbackOption,
  PAYMENT_OPTIONS,
} from "core/consts/systemConst";
import { apiCall } from "./apiCall";

export const setWalletPin = (userId: string, transactionPin: string) => {
  return apiCall({
    endpoint: "/wallet/setPin",
    method: "POST",
    body: {
      transactionPin,
      userId,
    },
  });
};

export const getWalletBalance = () => {
  return apiCall({
    endpoint: "/wallet/accountBalance",
    method: "POST",
    auth: true,
  });
};

export const getWalletDetails = () => {
  return apiCall({
    endpoint: "/profile/wallet/details",
    method: "GET",
    auth: true,
  });
};

export const getWalletTransactions = (query: TransactionQuery) => {
  return apiCall({
    endpoint: "/wallet/transactions",
    pQuery: { ...query },
    method: "GET",
    auth: true,
  });
};

export const fundWalletWithCard = (amount: number, transactionPin: string) => {
  return apiCall({
    endpoint: "/wallet/fundWallet",
    method: "POST",
    body: {
      amount,
      transactionPin,
      channelId: "web",
    },
    auth: true,
  });
};

export const getTransferFee = (amount: number, type: string) => {
  return apiCall({
    endpoint: `/wallet/transferfee?Amount=${amount}&PaymentType=${type}`,
    method: "GET",
    auth: true,
  });
};

export const completeGatewayTransaction = (
  data: PaymentGatewayFeedback,
  action: string,
) => {
  switch (true) {
    case action.includes("wallet"):
      return apiCall({
        endpoint: "/wallet/completeWalletFunding",
        method: "POST",
        body: {
          ...data,
        },
        auth: true,
      });
    case action.includes("events"):
    case action.includes("fast-food"):
    case action.includes("food-stuff"):
      return apiCall({
        endpoint: "/updateOrderStatus",
        method: "POST",
        body: {
          referenceId: data?.tx_ref,
          transaction_id: data?.transaction_id,
          status: data?.status,
          paymentOption: PAYMENT_OPTIONS[0]?.value,
          transactionType: GatewayFeedbackOption.Order,
        },
        auth: true,
      });
    case action.includes("transportation"):
      return apiCall({
        endpoint: "/updateOrderStatus",
        method: "POST",
        body: {
          referenceId: data?.tx_ref,
          transaction_id: data?.transaction_id,
          status: data?.status,
          paymentOption: PAYMENT_OPTIONS[0]?.value,
          transactionType: GatewayFeedbackOption.Transportation,
        },
        auth: true,
      });
    case action.includes("accommodation"):
    default:
      return {
        isSuccessful: false,
        status: 400,
        message: "Transaction could not be completed",
        data: null,
      };
  }
};

export const getOrderStatus = (orderId: string, action: string) => {
  switch (true) {
    case action.includes("transportation"):
      return apiCall({
        endpoint: "/transportation/confirmTripBookingOrLogisticsStatus",
        param: orderId,
        method: "GET",
        auth: true,
      });
    case action.includes("accommodation"):
    default:
      return apiCall({
        endpoint: "/getOrderStatus",
        param: orderId,
        method: "GET",
        auth: true,
      });
  }
};
