import React from "react";

const Profile = React.lazy(() => import("./Profile"));
const SettingsDashboard = React.lazy(() => import("./SettingsDashboard"));
const SettingsChangePassword = React.lazy(
  () => import("./SettingsChangePassword"),
);
const SettingsChangePin = React.lazy(() => import("./SettingsChangePin"));
const SettingsDeleteAccount = React.lazy(
  () => import("./SettingsDeleteAccount"),
);
const SettingsSecurityQuestion = React.lazy(
  () => import("./SettingsSecurityQuestion"),
);
const SettingsConfirmResetPinOtp = React.lazy(
  () => import("./SettingsConfirmResetPinOtp"),
);
const SettingsResetPin = React.lazy(() => import("./SettingsResetPin"));
const UpgradeAccount = React.lazy(() => import("./UpgradeAccount"));

const settingsRoute: RoutesType[] = [
  {
    name: "Upgrade Account",
    layout: "/portal",
    path: "profile/upgrade-account",
    icon: "",
    component: <UpgradeAccount />,
  },
  {
    name: "Profile",
    layout: "/portal",
    path: "profile",
    icon: "",
    component: <Profile />,
  },
  {
    name: "Change Password",
    layout: "/portal",
    path: "settings/change-password",
    icon: "",
    component: <SettingsChangePassword />,
  },
  {
    name: "Change Pin",
    layout: "/portal",
    path: "settings/change-pin",
    icon: "",
    component: <SettingsChangePin />,
  },
  {
    name: "Confirm Reset Pin Otp",
    layout: "/portal",
    path: "settings/confirm-reset-otp",
    icon: "",
    component: <SettingsConfirmResetPinOtp />,
  },
  {
    name: "Reset Pin",
    layout: "/portal",
    path: "settings/reset-pin",
    icon: "",
    component: <SettingsResetPin />,
  },
  {
    name: "Delete Account",
    layout: "/portal",
    path: "settings/delete-account",
    icon: "",
    component: <SettingsDeleteAccount />,
  },
  {
    name: "Security Question",
    layout: "/portal",
    path: "settings/security-question",
    icon: "",
    component: <SettingsSecurityQuestion />,
  },
  {
    name: "Settings Dashboard",
    layout: "/portal",
    path: "settings",
    icon: "",
    component: <SettingsDashboard />,
  },
];

export default settingsRoute;
