import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";

interface NotificationProps {
  title?: string;
  message?: string;
  onRemoval?: (id: string) => void;
  duration?: number;
  type: "default" | "success" | "info" | "warning" | "danger";
}

export function notify({
  title = "",
  message = "",
  type = "warning",
  onRemoval = () => {},
  duration = 5000,
}: NotificationProps) {
  const id = Store.addNotification({
    title: title,
    message: message,
    type: type,
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration,
      showIcon: true,
      onScreen: true,
      pauseOnHover: true,
    },
    onRemoval: () => onRemoval(id),
  });

  return { id, Store };
}

// Example usage within a React component or another function
// notify({
//   title: "Success",
//   message: "Your operation was successful",
//   type: "success"
// });
