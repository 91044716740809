import useUserStore from "core/services/stores/useUserStore";

interface Props {
  boxStyle?: string;
  showPhoneNumber?: boolean;
}

const UserPhotoBox = ({ boxStyle = "", showPhoneNumber = false }: Props) => {
  const user = useUserStore((store) => store.user);

  return (
    <div
      className={`flex flex-col items-center text-center text-white ${boxStyle}`}
    >
      <img
        className="mb-5 h-[50px] w-[50px] rounded-full bg-[#7a2fad]"
        src={user?.imagePath}
        alt="passport"
      />
      <p className="mb-3 text-[16px] font-semibold">
        {user?.firstName} {user?.lastName}
      </p>
      <p className="text-[#C7C4C4]">
        {showPhoneNumber ? user?.phoneNumber : user?.email}
      </p>
    </div>
  );
};

export default UserPhotoBox;
