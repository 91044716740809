import React from "react";
const ReferralDashboard = React.lazy(() => import("./ReferralDashboard"));

const referralRoutes: RoutesType[] = [
  {
    name: "Referral Dashboard",
    layout: "/portal",
    path: "referral",
    icon: "",
    component: <ReferralDashboard />,
  },
];

export default referralRoutes;
