import { apiCall } from "./apiCall";

export const getProfileDetails = () => {
  return apiCall({
    endpoint: "/profile/details",
    method: "GET",
    auth: true,
  });
};

export const changePassword = (data: ChangePassword) => {
  return apiCall({
    endpoint: "/profile/changePassword",
    method: "POST",
    auth: true,
    body: {
      ...data,
    },
  });
};

export const changeSecurityQuestion = (data: SecurityData) => {
  return apiCall({
    endpoint: "/profile/changeSecurityQuestion",
    method: "POST",
    auth: true,
    body: {
      ...data,
    },
  });
};

export const changePin = (data: ChangePin) => {
  return apiCall({
    endpoint: "/profile/changeTransactionPin",
    method: "POST",
    auth: true,
    body: {
      ...data,
    },
  });
};

export const getForgotPinOtp = () => {
  return apiCall({
    endpoint: "/profile/forgotTransactionPin",
    method: "POST",
    auth: true,
  });
};

export const validateForgotPinOtp = (otp: string) => {
  return apiCall({
    endpoint: "/profile/validateOtp",
    pQuery: { otp },
    method: "POST",
    auth: true,
  });
};

export const resetPin = (newPin: string, confirmPin: string) => {
  return apiCall({
    endpoint: "/profile/resetTransactionPin",
    method: "POST",
    auth: true,
    body: {
      newPin,
      confirmPin,
    },
  });
};

export const deleteAccount = (reason: string, suggestion: string) => {
  return apiCall({
    endpoint: "/profile/deleteMyAccount",
    method: "POST",
    auth: true,
    body: {
      reason,
      suggestion,
    },
  });
};

export const switchLocation = (location: string) => {
  return apiCall({
    endpoint: "/auth/switch-location",
    method: "POST",
    auth: true,
    body: {
      location,
    },
  });
};