import { filterObjectFields } from "core/helpers/generalHelpers";
import { apiCall } from "./apiCall";

export const getFastFoodVendor = () => {
  return apiCall({
    endpoint: "/marketplace/fastFood/vendor",
    method: "GET",
    auth: true,
  });
};

export const getFastFoodVendorById = (vendorId: string) => {
  return apiCall({
    endpoint: "/marketplace/fastFood/vendor",
    param: vendorId,
    method: "GET",
    auth: true,
  });
};

export const getFastFoodProductByVendorId = (vendorId: string) => {
  return apiCall({
    endpoint: "/marketplace/product/fast_food/vendor",
    param: vendorId,
    method: "GET",
    auth: true,
  });
};

export const placeFastFoodOrder = (
  order: FastFoodOrder,
  hasWallet: boolean = true,
) => {
  var requestBody = hasWallet ? order : { ...order, channelId: "web" };

  return apiCall({
    endpoint: hasWallet
      ? "/marketplace/order/fast_food"
      : "/marketplace/order/InitaiteFastFoodOrder",
    method: "POST",
    auth: true,
    body: requestBody,
  });
};

// Events
export const getEvents = (
  query: EventQuery,
  pageNumber: number,
  pageSize: number,
) => {
  return apiCall({
    endpoint: "/marketplace/product/event_ticket",
    method: "GET",
    //pQuery: filterObjectFields(query),
    auth: true,
  });
};

export const getEventsById = (eventId: string) => {
  return apiCall({
    endpoint: "/marketplace/product/event_ticket",
    param: eventId,
    method: "GET",
    auth: true,
  });
};

export const placeEventOrder = (
  order: EventOrder,
  hasWallet: boolean = true,
) => {
  var requestBody = hasWallet ? order : { ...order, channelId: "web" };

  return apiCall({
    endpoint: hasWallet
      ? "/marketplace/order/event_ticket"
      : "/marketplace/order/initaiteEventTicketPurchase",
    method: "POST",
    auth: true,
    body: requestBody,
  });
};
