import React from "react";

const WalletDashboard = React.lazy(() => import("./WalletDashboard"));
const AccountInformation = React.lazy(() => import("./AccountInformation"));
const CardFunding = React.lazy(() => import("./CardFunding"));
const FundAccount = React.lazy(() => import("./FundAccount"));

const walletRoutes: RoutesType[] = [
  {
    name: "Account Information",
    layout: "/portal",
    path: "wallet/account",
    icon: "",
    component: <AccountInformation />,
  },
  {
    name: "Card Funding",
    layout: "/portal",
    path: "wallet/funding/card",
    icon: "",
    component: <CardFunding />,
  },
  {
    name: "Fund Account",
    layout: "/portal",
    path: "wallet/funding",
    icon: "",
    component: <FundAccount />,
  },
  {
    name: "Wallet Dashboard",
    layout: "/portal",
    path: "wallet",
    icon: "",
    component: <WalletDashboard />,
  },
];

export default walletRoutes;
