import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { getDeliveryArea, getLocations } from "../api/location.api";

type LocationState = {
  isLoading: boolean;
  locations: CampusLocation[];
  deliveryAreaList: DeliveryAreaList;
  getLocations: () => Promise<void>;
  getDeliveryAreas: (
    query: DeliveryAreaQuery,
    pageNumber: number,
    pageSize: number,
  ) => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  locations: [],
  deliveryAreaList: {
    data: [],
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPage: 0,
  },
};

const useLocationStore = create<LocationState>()(
  devtools(
    persist(
      (set, get): LocationState => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        getLocations: async () => {
          set({ isLoading: true });

          var res = await getLocations();

          if (res?.isSuccessful) {
            set({ locations: res?.data });
          } else {
            set({ locations: [] });
          }

          set({ isLoading: false });
        },
        getDeliveryAreas: async (query, pageNumber, pageSize) => {
          set({ isLoading: true });

          var res: UIResponse = await getDeliveryArea(
            query,
            pageNumber,
            pageSize,
          );

          if (res?.isSuccessful) {
            set({
              deliveryAreaList: {
                data: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
      }),
      {
        name: "locationStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useLocationStore;
