import { apiCall } from "./apiCall";

export const getFoodItem = (
  query?: FoodStuffQuery,
  pageNumber?: number,
  pageSize?: number,
) => {
  return apiCall({
    endpoint: "/foodstuff/foodItem",
    method: "GET",
    auth: true,
  });
};

export const getFoodItemById = (id: string) => {
  return apiCall({
    endpoint: "/foodstuff/foodItem",
    param: id,
    method: "GET",
    auth: true,
  });
};

export const placeFoodStuffOrder = (
  order: FoodStuffOrder,
  hasWallet: boolean = true,
) => {
  var requestBody = {};

  if (hasWallet) {
    requestBody = {
      ...order,
      order: [
        ...order?.order?.map((item) => ({
          quantity: item?.quantity,
          productName: item?.productName,
          foodStuffId: item?.foodStuffId,
          amount: item?.amount,
          imageUrl: item?.imageUrl,
        })),
      ],
    };
  } else {
    requestBody = {
      ...order,
      order: [
        ...order?.order?.map((item) => ({
          quantity: item?.quantity,
          productName: item?.productName,
          foodStuffId: item?.foodStuffId,
          amount: item?.amount,
          imageUrl: item?.imageUrl,
        })),
      ],
      channelId: "web",
    };
  }

  return apiCall({
    endpoint: hasWallet
      ? "/foodstuff/order"
      : "/foodstuff/order/InitiateOrderFoodStuff",
    method: "POST",
    auth: true,
    body: requestBody,
  });
};
