import { apiCall } from "./apiCall";

export const reportIssue = (data: Issue) => {
  return apiCall({
    endpoint: "/support/client/log",
    method: "POST",
    auth: true,
    body: {
      ...data,
    },
  });
};