import HeaderPlain from "core/components/layouts/HeaderPlain";
import { addMetaData } from "core/helpers/seoHelpers";
import bus1 from "assets/img/bus1.svg";
import bus2 from "assets/img/bus2.svg";
import doubleArrow from "assets/img/doubleArrow.svg";
import filterIcon from "assets/img/filter.svg";
import locationIcon from "assets/img/locationGray.svg";
import clockIcon from "assets/img/clockGray.svg";
import { Link } from "react-router-dom";
import { cx } from "core/helpers/generalHelpers";
import { notify } from "core/helpers/notify";
import useTransportationStore from "core/services/stores/useTransportationStore";
import { useEffect } from "react";
import { TripInfo } from "modules/portal/views/partials/TripInfo";

const BusInTransit = () => {
  const tripsInTransit = useTransportationStore(
    (store) => store.tripsInTransit,
  );
  const getTripsInTransitAction = useTransportationStore(
    (store) => store.getTripsInTransit,
  );
  const setTripInTransit = useTransportationStore(
    (store) => store.setTripInTransit,
  );

  useEffect(() => {
    getTripsInTransitAction();
  }, []);

  return (
    <div className="pb-[100px]">
      {addMetaData({
        title: "",
        description: "",
      })}

      <HeaderPlain
        showTitleLocation
        title="Buses on Transit"
        navigateBack
        showLogo={false}
        backUrl="/portal/transportation/share-bus"
      />
      <main className="mx-auto w-11/12 md:w-4/5">
        <section>
          {tripsInTransit?.length > 0 ? (
            tripsInTransit?.map((trip) => (
              <>
                <div key={trip?.id} className="mb-8">
                  <p className="mb-3 text-center font-semibold">
                    {trip?.routeName}
                  </p>

                  <div className="grid-2 mb-3 grid grid-cols-4 items-center gap-2 text-gray">
                    <div className="col-span-1 flex items-center gap-2">
                      <p>Take off</p>
                      <img loading="lazy" src={clockIcon} alt="" />
                    </div>
                    <div className="col-span-2 flex items-center gap-2">
                      <p>Various Routes</p>
                      <img loading="lazy" src={locationIcon} alt="" />
                    </div>
                  </div>

                  {trip?.busInfo?.routeInfo?.map((busRoute) => (
                    <div
                      key={busRoute?.id}
                      className={cx(
                        "grid-2 mb-3 grid grid-cols-4 items-center gap-2",
                      )}
                    >
                      <div className="col-span-1">
                        <p>{trip?.departureTime}</p>
                      </div>
                      <div className="col-span-2">
                        <p>{busRoute?.busStop}</p>
                      </div>
                      <div className="col-span-1 text-center">
                        <Link
                          to={`/portal/transportation/share-bus/trips-in-transit/${busRoute?.id}`}
                          onClick={() => {
                            setTripInTransit(trip);
                          }}
                          className="inline-block w-full rounded-[24px] bg-[#F2F2F3] px-[10px] py-[6px] text-center text-[14px] font-normal text-gray"
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ))
          ) : (
            <p>There are no trips on transit at the moment</p>
          )}
        </section>
      </main>
    </div>
  );
};

export default BusInTransit;
