import { ORDER_STATUS } from "core/consts/systemConst";
import moment from "moment-timezone";

export const isNumeric = (str: string) => {
  return /^\d+$/.test(str);
};

export const isAlphanumeric = (str: string | undefined | number) => {
  if (str == null) return false;

  const hasAlphabet = /[A-Za-z]/.test(str?.toString());
  const hasNumber = /[0-9]/.test(str?.toString());
  return hasAlphabet && hasNumber;
};

export const hasSpecialCharacters = (str: string | undefined | number) => {
  if (str == null) return false;

  const regex = /[^A-Za-z0-9]/;
  return regex.test(str?.toString());
};

export const numbersOnly = (e: any) => {
  if (isNaN(e?.key) && e?.key !== "Backspace") {
    e.preventDefault();
  }
};

export const validateNoWhitespaceBetweenWords = (
  str: string | undefined | number,
): boolean => {
  if (str == null || str!?.toString().length < 1) return false;

  const whitespaceRegex = /\s/;
  return !whitespaceRegex.test(str!?.toString());
};

export const isEmail = (input: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
};

export const validatePassword = (password: string) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;

  return passwordRegex.test(password) && !/\s/.test(password);
};

export function isValidPin(pin: string) {
  return /^\d{4}$/.test(pin);
}

export const formatSimpleDate = (date: any, isDash = false) => {
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return null;

  var formattedDate = "";
  if (!isDash) {
    formattedDate = `${appendLeadZero(d.getDate())}/${appendLeadZero(
      d.getMonth() + 1,
    )}/${d.getFullYear()}`;
  } else {
    formattedDate = `${appendLeadZero(d.getDate())}-${appendLeadZero(
      d.getMonth() + 1,
    )}-${d.getFullYear()}`;
  }
  return formattedDate;
};

export const formatWordDate = (dateString?: string, showTime?: boolean) => {
  try {
    const date = new Date(dateString!);

    const dayName = date.toLocaleString("default", { weekday: "short" });

    const monthName = date.toLocaleString("default", { month: "short" });

    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    let time = "";

    if (showTime) {
      const hours = date.getHours();

      const minutes = String(date.getMinutes()).padStart(2, "0");

      const amPm = hours >= 12 ? "PM" : "AM";

      const hour = String(hours % 12 || 12).padStart(2, "0");

      time = ` ${hour}:${minutes} ${amPm}`;
    }

    return `${dayName} ${monthName} ${day}, ${year} ${time}`;
  } catch {
    return "";
  }
};

export const formatToFormDate = (date: any, isDash = false) => {
  if (date == null || date?.length < 1) return "";
  const d = new Date(date);

  var formattedDate = `${d.getFullYear()}-${appendLeadZero(
    d.getMonth() + 1,
  )}-${appendLeadZero(d.getDate())}`;

  return formattedDate;
};

const timeFormat12Hour = (date: any) => {
  let h = date.getHours();
  let m = date.getMinutes();
  let ampm = h >= 12 ? "pm" : "am";

  h = h % 12; //reminder
  h = h ? h : 12;

  m = m.toString().padStart(2, "0");
  const formatedTimeString = h + ":" + m + " " + ampm;
  return formatedTimeString;
};

const appendLeadZero = (val: any) => (Number(val) > 9 ? val : `0${val}`);

export const formatCurrency = (value: any) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "₦ 0.00";
  }

  let val: string | number = parseFloat(value);
  if (isNaN(val)) {
    return "₦ 0.00";
  }

  if (!String(value).includes(".")) {
    return `₦ ${Number(val).toLocaleString("en-US")}`;
  }

  val = val.toFixed(2);
  return `₦ ${Number(val).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

export const formatNumber = (value: any) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "0.00";
  }

  let val: string | number = parseFloat(value);
  if (isNaN(val)) {
    return "0.00";
  }

  if (!String(value).includes(".")) {
    return `${Number(val).toLocaleString("en-US")}`;
  }

  val = val.toFixed(2);
  return `${Number(val).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

export const getDataFromSession = (name: string) => {
  try {
    const data: any = sessionStorage.getItem(name);
    if (
      data !== null &&
      data !== undefined &&
      typeof JSON.parse(data) === "object" &&
      !Array.isArray(data)
    ) {
      return JSON.parse(data);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const isToday = (date: any) => {
  const theDate = new Date(date);
  const today = new Date();
  return today.setHours(0, 0, 0, 0) === theDate.setHours(0, 0, 0, 0);
};

export function isDateGreaterThanToday(date: string) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const givenDate = new Date(date);
  return givenDate > today;
}

export const isTimeNotPassed = (date: string, time: string) => {
  try {
    const now = new Date();
    let [hours, minutes]: any = time.split(/[:\s]+/);
    const meridiem = time.split(" ")[1];

    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (meridiem.toUpperCase() === "PM" && hours < 12) {
      hours += 12;
    } else if (meridiem.toUpperCase() === "AM" && hours === 12) {
      hours = 0;
    }

    const inputDate = new Date(date);
    inputDate.setHours(hours, minutes, 0, 0);
    return inputDate > now;
  } catch (error) {
    return false;
  }

  // Compare the dates
};

export const getDate = (dateString: any, showYear = true) => {
  const date = new Date(dateString);

  if (showYear) {
    return `${
      MONTHS[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  } else {
    return `${MONTHS[date.getMonth()]} ${date.getDate()}`;
  }
};

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const getTimes = () => {
  const times = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 13; i++) {
    times.push({ name: `${i}:00 AM` });
    times.push({ name: `${i}:00 PM` });
    times.push({ name: `${i}:25 AM` });
    times.push({ name: `${i}:25 PM` });
    times.push({ name: `${i}:30 AM` });
    times.push({ name: `${i}:30 PM` });
    times.push({ name: `${i}:45 AM` });
    times.push({ name: `${i}:45 PM` });
  }

  return times;
};

export const addSpaceToCamelCase = (word: string) =>
  word.replace(/([A-Z])/g, " $1").trim();

export const stringToNumber = (word: string | number) => {
  var res = +word;

  if (isNaN(res)) return 0;

  return res;
};

export const isObjectEmpty = (value: any) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  return Object.keys(value).length === 0;
};

export const isValidPassword = (val: string) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{9,}$/.test(val);
};

export const isValidFormDate = (val: string) => {
  return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(val);
};

export const capitalizeFirstWord = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getDateTime = (date: string) => {
  if (!date) return null;

  if (date.match(/\d{2}\/\d{2}\/\d{4}/)) {
    return moment
      .tz(date, "MM/DD/YYYY", "Africa/Lagos")
      .format("Do MMM YYYY, h:mm A");
  }
  if (date.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+/)) {
    return moment
      .tz(date, "YYYY-MM-DDTHH:mm:ss.SSSZ", "Africa/Lagos")
      .format("Do MMM YYYY, h:mm A");
  }
  if (date.match(/\d{4}-\d{2}-\d{2}/)) {
    return moment
      .tz(date, "YYYY-MM-DD", "Africa/Lagos")
      .format("Do MMM YYYY, h:mm A");
  }
  return null;
};

export const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId);

  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const scrollWindowToTop = () => {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    window.scrollTo(0, 0);
  }
};

export const clearSessionAndLogout = async () => {
  localStorage.clear();
  sessionStorage.clear();
  //isExpiredSession && localStorage.setItem('se', true);
  window.location.assign("/");
};

export const getImageFile = (name: string, imgUrl: string | null) => {
  if (imgUrl != null) {
    const base64Data = imgUrl.replace(/^data:image\/jpeg;base64,/, "");
    const rawImageFile = new Blob([base64Data], { type: "image/jpeg" });

    const file = new File([rawImageFile], `${name ?? "photo"}.jpeg`, {
      type: "image/jpeg",
    });

    return file;
  }

  return null;
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const getPaymentStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "!text-yellow-500";
    default:
      return "text-black";
  }
};

export const getOrderStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case ORDER_STATUS.pending?.toLowerCase():
    case ORDER_STATUS.processing?.toLowerCase():
      return "!bg-[#f3d191]";
    case ORDER_STATUS.cancelled?.toLowerCase():
    case ORDER_STATUS.failed?.toLowerCase():
      return "!bg-[#ff6e6e] !text-white";
    case ORDER_STATUS.delivered?.toLowerCase():
      return "!bg-[#b8f3b6] !text-black-900";
    case ORDER_STATUS.awaiting_payment?.toLowerCase():
      return "!bg-[#87CEEB] !text-black";
    default:
      return "!bg-[#f7f7f8]";
  }
};

export const cx = (...classNames: string[]) =>
  classNames.filter(Boolean).join(" ");

export const filterObjectFields = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
};

export const isAgeValid = (dateOfBirth: string) => {
  const currentDate = new Date();
  const minDate = new Date();

  minDate.setFullYear(currentDate.getFullYear() - 12);

  const birthDate = new Date(dateOfBirth);

  // Compare the dates
  return birthDate <= minDate;
};
