import React from "react";

const OrderDashboard = React.lazy(() => import("./OrderDashboard"));
const OrderDetails = React.lazy(() => import("./OrderDetails"));

const orderRoutes: RoutesType[] = [
  {
    name: "Order Details",
    layout: "/portal",
    path: "orders/:orderId",
    icon: "",
    component: <OrderDetails />,
  },
  {
    name: "Order Dashboard",
    layout: "/portal",
    path: "orders",
    icon: "",
    component: <OrderDashboard />,
  },
];

export default orderRoutes;
