import { cx, formatCurrency } from "core/helpers/generalHelpers";
import { notify } from "core/helpers/notify";
import copyIcon from "assets/img/copy.svg";

export const Info = ({
  title = "",
  value = "",
  isNumber = false,
  styling = "",
  allowCopy = false,
  children,
}: {
  title?: string;
  value: string | number;
  isNumber?: boolean;
  styling?: string;
  allowCopy?: boolean;
  children?: React.ReactNode;
}) => {
  const handleCopy = () => {
    notify({
      type: "success",
      message: `${title} copied`,
    });
    navigator.clipboard.writeText(value.toString());
  };

  return (
    <div
      className={cx(
        "mb-3 flex items-center justify-between gap-2 rounded-[8px] bg-white p-3",
        styling,
      )}
    >
      <p className="capitalize text-gray">{title}</p>
      {children}
      <div className="flex items-center justify-end gap-2">
        <p className="text-black">{isNumber ? formatCurrency(value) : value}</p>
        {allowCopy && (
          <img
            className="hover:cursor-pointer"
            onClick={() => handleCopy()}
            src={copyIcon}
            alt={`copy ${title}`}
          />
        )}
      </div>
    </div>
  );
};
