import { cx, formatCurrency } from "core/helpers/generalHelpers";

export const OrderSummary = ({
  title = "",
  amount = 0,
  styling = "",
  isNegative = false,
}: {
  title?: string;
  amount: number;
  styling?: string;
  isNegative?: boolean;
}) => {
  return (
    <div
      className={cx(
        "mb-5 flex items-center justify-between gap-3 font-light",
        styling,
      )}
    >
      <p className="w-1/2 text-gray">{title}</p>
      <p className="text-purple-dark">
        {isNegative && "-"} {formatCurrency(amount)}
      </p>
    </div>
  );
};
