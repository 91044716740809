import React from "react";
import BankTransferTransaction from "./views/BankTransferTransaction";

const Dashboard = React.lazy(() => import("./views/Dashboard"));
const NotFound = React.lazy(() => import("./views/NotFound"));
const ReportIssue = React.lazy(() => import("./views/ReportIssue"));
const HelpAndSupport = React.lazy(() => import("./views/HelpAndSupport"));
const CompleteTransaction = React.lazy(
  () => import("./views/CompleteTransaction"),
);

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/portal",
    path: "dashboard",
    icon: "",
    component: <Dashboard />,
  },
  {
    name: "Dashboard",
    layout: "/portal",
    path: "report",
    icon: "",
    component: <ReportIssue />,
  },
  {
    name: "Dashboard",
    layout: "/portal",
    path: "support",
    icon: "",
    component: <HelpAndSupport />,
  },
  {
    name: "Complete Transaction",
    layout: "/portal",
    path: "complete-transaction",
    icon: "",
    component: <CompleteTransaction />,
  },
  {
    name: "Bank Transaction",
    layout: "/portal",
    path: "bank-transaction",
    icon: "",
    component: <BankTransferTransaction />,
  },
  {
    name: "Not Found",
    layout: "/portal",
    path: "notfound",
    icon: "",
    component: <NotFound />,
  },
];

export default routes;
