import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  getEvents,
  getEventsById,
  getFastFoodProductByVendorId,
  getFastFoodVendor,
  getFastFoodVendorById,
  placeEventOrder,
  placeFastFoodOrder,
} from "../api/marketplace.api";
import { notify } from "core/helpers/notify";

type StoreState = {
  isLoading: boolean;
  fastFoodVendors: FastFoodVendor[];
  selectedFastFoodVendor: FastFoodVendor | null;
  fastFoodProducts: FoodStuffProduct[];
  fastFoodShopList: FastFoodShopList;
  eventList: EventList;
  selectedEvent: Event | null;
  eventShopList: EventShopList;
  setSelectedVendor: (vendor: FastFoodVendor | null) => void;
  getFastFoodVendors: () => Promise<void>;
  getFastFoodVendorById: (vendorId: string) => Promise<void>;
  getFastFoodProductByVendorId: (vendorId: string) => Promise<void>;
  addToShopList: (product: FastFoodCart, quantity: number) => Promise<void>;
  deleteFromCart: (productId: string) => Promise<void>;
  placeFastFoodOrder: (
    order: FastFoodOrder,
    hasWallet: boolean,
  ) => Promise<UIResponse>;
  emptyCart: () => Promise<void>;
  getEventList: (query: EventQuery) => Promise<void>;
  getEventById: (id: string) => Promise<void>;
  addToEventCart: (eventItem: EventCartItem, quantity: number) => Promise<void>;
  deleteFromEventCart: (category: string) => Promise<void>;
  placeEventOrder: (
    order: EventOrder,
    hasWallet: boolean,
  ) => Promise<UIResponse>;
  emptyEventCart: () => Promise<void>;
  reset: () => void;
};

const defaultState = {
  isLoading: false,
  fastFoodVendors: [],
  fastFoodProducts: [],
  selectedEvent: null,
  selectedFastFoodVendor: null,
  fastFoodShopList: {
    vendor: null,
    cartItems: [],
  },
  eventList: {
    data: [],
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPage: 0,
  },
  eventShopList: {
    productId: "",
    ticketType: "",
    convenienceFee: 0,
    cartItems: [],
  },
};

const useMarketplaceStore = create<StoreState>()(
  devtools(
    persist(
      (set, get): StoreState => ({
        ...defaultState,
        reset: () => {
          set({ ...defaultState });
        },
        setSelectedVendor: (vendor) => {
          set({ selectedFastFoodVendor: vendor });
        },
        getFastFoodVendors: async () => {
          set({ isLoading: true });

          var res = await getFastFoodVendor();

          if (res?.isSuccessful) {
            set({ fastFoodVendors: res?.data?.data });
          } else {
            set({ fastFoodVendors: [] });
          }

          set({ isLoading: false });
        },
        getFastFoodProductByVendorId: async (vendorId) => {
          set({ isLoading: true });

          var res = await getFastFoodProductByVendorId(vendorId);

          if (res?.isSuccessful) {
            set({ fastFoodProducts: res?.data?.data });
          } else {
            set({ fastFoodProducts: [] });
          }

          set({ isLoading: false });
        },
        getFastFoodVendorById: async (vendorId) => {
          set({ isLoading: true });

          var res = await getFastFoodVendorById(vendorId);

          if (res?.isSuccessful) {
            set({ selectedFastFoodVendor: res?.data?.data });
          } else {
            set({ selectedFastFoodVendor: null });
          }

          set({ isLoading: false });
        },
        deleteFromCart: async (productId) => {
          set((state) => ({
            fastFoodShopList: {
              vendor:
                state?.fastFoodShopList?.cartItems?.length < 2
                  ? null
                  : state.fastFoodShopList.vendor,
              cartItems: state.fastFoodShopList?.cartItems.filter(
                (item) => item.productId !== productId,
              ),
            },
          }));
        },
        addToShopList: async (product, quantity) => {
          var vendorInCart: any = get().fastFoodShopList.vendor?.vendorId;
          var selectedFastFoodVendor = get().selectedFastFoodVendor;

          if (selectedFastFoodVendor == null) {
            notify({
              type: "warning",
              message: "Please select a vendor",
            });
          } else {
            if (
              vendorInCart == null ||
              vendorInCart == selectedFastFoodVendor?.vendorId
            ) {
              set((state) => ({
                fastFoodShopList: {
                  vendor: selectedFastFoodVendor,
                  cartItems: state?.fastFoodShopList?.cartItems.some(
                    (x) => x.productId == product?.productId,
                  )
                    ? state?.fastFoodShopList?.cartItems?.map((item) =>
                        item.productId === product?.productId
                          ? {
                              ...item,
                              quantity: +quantity,
                            }
                          : item,
                      )
                    : [
                        ...state.fastFoodShopList?.cartItems,
                        {
                          ...product,
                          quantity: quantity,
                        },
                      ],
                },
              }));
            } else {
              notify({
                type: "warning",
                message: "You can only shop from the same vendor",
              });
            }
          }
        },
        emptyCart: async () => {
          set({
            fastFoodShopList: {
              vendor: null,
              cartItems: [],
            },
          });
        },
        placeFastFoodOrder: async (order, hasWallet) => {
          set({ isLoading: true });

          var res = await placeFastFoodOrder(order, hasWallet);

          var message: any = "";

          if (res?.isSuccessful) {
            if (hasWallet) {
              message = "Your order has been placed successfully";
            } else {
              message = res?.data?.message;
            }
          } else {
            message = res?.data?.message ?? res?.data?.error?.message;
          }

          notify({
            message: message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        getEventList: async (query) => {
          set({ isLoading: true });

          var res: UIResponse = await getEvents(query, 1, 1);

          if (res?.isSuccessful) {
            set({
              eventList: {
                data: res?.data?.data?.data,
                pageNumber: res?.data?.data?.pageNumber,
                pageSize: res?.data?.data?.pageSize,
                totalCount: res?.data?.data?.totalCount,
                totalPage: res?.data?.data?.totalPage,
              },
            });
          }

          set({ isLoading: false });
        },
        getEventById: async (id) => {
          set({ isLoading: true });

          var res = await getEventsById(id);

          if (res?.isSuccessful) {
            var event = res?.data?.data as Event;

            set({ selectedEvent: { ...event } });

            set({
              eventShopList: {
                productId: event?.productId,
                ticketType: event?.ticketType,
                convenienceFee: event?.convenienceFee,
                cartItems: event?.eventCategories?.map((show) => ({
                  category: show?.category,
                  price: show?.amount,
                  quantity: 0,
                })),
              },
            });
          } else {
            set({ selectedEvent: null });
          }

          set({ isLoading: false });
        },
        addToEventCart: async (eventItem, quantity) => {
          var eventInCart: any = get().eventShopList?.productId;
          var selectedEvent = get().selectedEvent;

          if (selectedEvent == null) {
            notify({
              type: "warning",
              message: "Please select an event",
            });
          } else {
            if (
              eventInCart?.length < 1 ||
              eventInCart == selectedEvent?.productId
            ) {
              set((state) => ({
                eventShopList: {
                  productId: selectedEvent?.productId ?? "",
                  ticketType: selectedEvent?.ticketType!,
                  convenienceFee: selectedEvent?.convenienceFee!,
                  cartItems: state?.eventShopList?.cartItems.some(
                    (x) => x.category == eventItem?.category,
                  )
                    ? state?.eventShopList?.cartItems?.map((item) =>
                        item.category === eventItem?.category
                          ? {
                              ...item,
                              quantity: +quantity,
                            }
                          : item,
                      )
                    : [
                        ...state.eventShopList?.cartItems,
                        {
                          ...eventItem,
                          quantity: quantity,
                        },
                      ],
                },
              }));
            } else {
              notify({
                type: "warning",
                message: "You can only book ticket from same events",
              });
            }
          }
        },
        emptyEventCart: async () => {
          set({
            eventShopList: {
              productId: "",
              ticketType: "",
              convenienceFee: 0,
              cartItems: [],
            },
          });
        },
        placeEventOrder: async (order, hasWallet) => {
          set({ isLoading: true });

          var res = await placeEventOrder(order, hasWallet);

          var message: any = "";

          if (res?.isSuccessful) {
            if (hasWallet) {
              message = "Your order has been placed successfully";
            } else {
              message = res?.data?.message;
            }
          } else {
            message = res?.data?.message ?? res?.data?.error?.message;
          }

          notify({
            message: message,
            type: res?.isSuccessful ? "success" : "danger",
          });

          set({ isLoading: false });
          return res;
        },
        deleteFromEventCart: async (category) => {
          set((state) => ({
            eventShopList: {
              productId:
                state?.eventShopList?.cartItems?.length > 2
                  ? state?.eventShopList?.productId
                  : "",
              ticketType:
                state?.eventShopList?.cartItems?.length > 2
                  ? state?.eventShopList?.ticketType
                  : "",
              convenienceFee:
                state?.eventShopList?.cartItems?.length > 2
                  ? state?.eventShopList?.convenienceFee
                  : 0,
              cartItems: state.eventShopList?.cartItems.filter(
                (item) => item.category !== category,
              ),
            },
          }));
        },
      }),
      {
        name: "marketplaceStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useMarketplaceStore;
