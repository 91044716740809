import useUserStore from "core/services/stores/useUserStore";
import home from "assets/img/home2.svg";
import profileIcon from "assets/img/profile.svg";
import peopleIcon from "assets/img/people.svg";
import settings from "assets/img/settings.svg";
import feedback from "assets/img/feedback.svg";
import alertIcon from "assets/img/alert2.svg";
import logoutIcon from "assets/img/logout.svg";
import UserPhotoBox from "../UserPhotoBox";
import { useNavigate } from "react-router-dom";

const SideNav = ({ logOut = () => {} }: { logOut: any }) => {
  const navigate = useNavigate();
  const toggleNavAction = useUserStore((store) => store.toggleSideNav);
  const isSideNavOpen = useUserStore((store) => store.isSideNavOpen);

  const sideLinks = [
    {
      name: "Dashboard",
      url: "/portal/dashboard",
      icon: home,
    },
    {
      name: "View Profile",
      url: "/portal/profile",
      icon: profileIcon,
    },
    {
      name: "Refer a Friend",
      url: "/portal/referral",
      icon: peopleIcon,
    },
    {
      name: "Settings",
      url: "/portal/settings",
      icon: settings,
    },
    {
      name: "Report an Issue",
      url: "/portal/report",
      icon: alertIcon,
    },
    {
      name: "Help and Support",
      url: "/portal/support",
      icon: feedback,
    },
    {
      name: "Logout",
      url: "",
      icon: logoutIcon,
    },
  ];

  return (
    <section
      className={`sideNavbar fixed h-full w-2/3 !bg-purple p-5 pt-4 transition-all duration-500 ease-in-out md:w-[300px] ${
        isSideNavOpen ? "-left-0" : "-left-[100vw]"
      }`}
      style={{
        zIndex: 1000,
      }}
    >
      <UserPhotoBox />

      <nav className="mt-[60px] text-white">
        {sideLinks?.map((link) => (
          <button
            key={link?.name}
            onClick={() => {
              toggleNavAction(false);
              if (link?.url?.length > 0) {
                navigate(link?.url);
              } else {
                logOut();
              }
            }}
            className="mb-2 flex w-full items-center gap-5 border-b-[.5px] py-5 hover:cursor-pointer"
          >
            <img src={link?.icon} alt="" />
            <p>{link?.name}</p>
          </button>
        ))}
      </nav>
    </section>
  );
};

export default SideNav;
