import { Routes, Route, Navigate } from "react-router-dom";
import { getRoutes } from "core/helpers/getRoutes";
import routes from "./routes";

export default function Auth() {
  return (
    <>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="*" element={<Navigate to="/notfound" replace />} />
      </Routes>
    </>
  );
}
