import { Helmet } from "react-helmet";
import logoImg from "assets/img/logo.svg";

interface MetaDataProps {
  title?: string;
  description?: string;
}

export const addMetaData = ({
  title = "Campusrunz Web",
  description = "",
}: MetaDataProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />

      {/* Open Graph / Facebook Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={logoImg} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logoImg} />
    </Helmet>
  );
};
