import { apiCall } from "./apiCall";

export const getUserBookedTrip = (startDate?: string, endDate?: string) => {
  return apiCall({
    endpoint: "/transportation/getBookingsByUserAndPeriod",
    pQuery: { startDate, endDate },
    method: "GET",
    auth: true,
  });
};

export const getAllTripInLocation = () => {
  return apiCall({
    endpoint: "/transportation/getAllTrips",
    method: "GET",
    auth: true,
  });
};

export const getAllTripByDate = (travelDate: string) => {
  return apiCall({
    endpoint: "/transportation/getAllTripsByDate",
    param: travelDate,
    method: "GET",
    auth: true,
  });
};

export const getAvailableTrips = (departureDate?: string, tripId?: string) => {
  return apiCall({
    endpoint: "/transportation/GetAllAvailableTripsBasedOnSearchParam",
    method: "POST",
    body: {
      departureDate,
      tripId,
    },
    auth: true,
  });
};

export const getTripsInTransit = () => {
  return apiCall({
    endpoint: "/transportation/getTripsInTransit",
    method: "GET",
    auth: true,
  });
};

export const scheduleTrip = (trip: NewTripSchedule, hasWallet?: boolean) => {
  var requestBody = hasWallet
    ? {
        fare: trip?.fare,
        convenienceFee: trip?.convenienceFee,
        tripId: trip?.tripId,
        desiredSeats: trip?.desiredSeats,
        tripType: trip?.tripType,
        desiredBusStopId: trip?.desiredBusStopId,
        useCashBackBalance: trip?.useCashBackBalance,
      }
    : {
        fare: trip?.fare,
        convenienceFee: trip?.convenienceFee,
        tripId: trip?.tripId,
        desiredSeats: trip?.desiredSeats,
        tripType: trip?.tripType,
        desiredBusStopId: trip?.desiredBusStopId,
        useCashBackBalance: trip?.useCashBackBalance,
        paymentOption: trip?.paymentOption,
        channelId: "web",
      };

  return apiCall({
    endpoint: hasWallet
      ? "/transportation/scheduleTrip"
      : "/transportation/InitaiteScheduleTrip",
    method: "POST",
    body: requestBody,
    auth: true,
  });
};

export const joinOngoingTrip = (
  trip: OngoingTripSchedule,
  hasWallet?: boolean,
) => {
  var requestBody = hasWallet
    ? trip
    : {
        ...trip,
        channelId: "web",
      };

  return apiCall({
    endpoint: hasWallet
      ? "/transportation/joinOngoingTrip"
      : "/transportation/InitaiteJoinOngoingTrip",
    method: "POST",
    body: requestBody,
    auth: true,
  });
};

export const sharedTripBill = (bill: SharedTripBill) => {
  return apiCall({
    endpoint: "/Transportation/InitiateHelpAFriendWithBooking",
    method: "POST",
    body: bill,
    auth: true,
  });
};
