import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "assets/css/app.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <div className="relative">
    <App />
  </div>,
);
