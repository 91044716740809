import React from "react";

const AlertDashboard = React.lazy(() => import("./AlertDashboard"));
const AlertDetails = React.lazy(() => import("./AlertDetails"));

const alertRoutes: RoutesType[] = [
  {
    name: "Alert Details",
    layout: "/portal",
    path: "alerts/:alertId",
    icon: "",
    component: <AlertDetails />,
  },
  {
    name: "Alert Dashboard",
    layout: "/portal",
    path: "alerts",
    icon: "",
    component: <AlertDashboard />,
  },
];

export default alertRoutes;
