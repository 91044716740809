import React from "react";
import BusInTransit from "./shareBus/busInTransit/BusInTransit";
import JoinTripInTransit from "./shareBus/busInTransit/JoinTripInTransit";

const BookTrip = React.lazy(() => import("./shareBus/scheduleTrip/BookTrip"));
const ShareTripBillWithFriend = React.lazy(
  () => import("./shareBus/scheduleTrip/ShareTripBillWithFriend"),
);
const ScheduleTrip = React.lazy(
  () => import("./shareBus/scheduleTrip/ScheduleTrip"),
);
const ShareBus = React.lazy(() => import("./shareBus/ShareBus"));
const TripDetails = React.lazy(() => import("./shareBus/TripDetails"));
const HireBus = React.lazy(() => import("./hireBus/HireBus"));
const Courier = React.lazy(() => import("./courier/Courier"));
const TransportDashboard = React.lazy(() => import("./TransportDashboard"));

const transportRoutes: RoutesType[] = [
  {
    name: "",
    layout: "/portal",
    path: "transportation/share-bus/trip/:tripId",
    icon: "",
    component: <TripDetails />,
  },
  {
    name: "",
    layout: "/portal",
    path: "transportation/share-bus/book-trip/share-bill",
    icon: "",
    component: <ShareTripBillWithFriend />,
  },
  {
    name: "",
    layout: "/portal",
    path: "transportation/share-bus/trips-in-transit/:busStopId",
    icon: "",
    component: <JoinTripInTransit />,
  },
  {
    name: "",
    layout: "/portal",
    path: "transportation/share-bus/book-trip",
    icon: "",
    component: <BookTrip />,
  },
  {
    name: "",
    layout: "/portal",
    path: "transportation/share-bus/schedule-trip",
    icon: "",
    component: <ScheduleTrip />,
  },
  {
    name: "",
    layout: "/portal",
    path: "transportation/share-bus/trips-in-transit",
    icon: "",
    component: <BusInTransit />,
  },
  {
    name: "Share bus",
    layout: "/portal",
    path: "transportation/share-bus",
    icon: "",
    component: <ShareBus />,
  },
  {
    name: "Hire bus",
    layout: "/portal",
    path: "transportation/hire-bus",
    icon: "",
    component: <HireBus />,
  },
  {
    name: "Courier",
    layout: "/portal",
    path: "transportation/courier",
    icon: "",
    component: <Courier />,
  },
  {
    name: "Transport",
    layout: "/portal",
    path: "transportation",
    icon: "",
    component: <TransportDashboard />,
  },
];

export default transportRoutes;
