import { apiCall } from "./apiCall";

/**
 * Initiates a user login in.
 * @param username The username of the user trying to sign in.
 * @param password The password of the user trying to sign in.
 * @returns A Promise resolving to the API call's response.
 */
export const signIn = (username: string, password: string) => {
  return apiCall({
    endpoint: "/auth/login",
    method: "POST",
    body: { email_phone: username, password, appVersion: 50000 },
  });
};

export const verifyPhoneNumber = (phoneNumber: string) => {
  return apiCall({
    endpoint: "/auth/verify_phone",
    method: "POST",
    body: { phoneNumber },
  });
};

export const validateOTP = (phoneNumber: string, token: string) => {
  return apiCall({
    endpoint: "/auth/validate_smsToken",
    param: `${phoneNumber}/${token}`,
    method: "GET",
  });
};

export const registerNewUser = (user: NewUser) => {
  var formData = new FormData();

  formData.append("FirstName", user?.firstName);
  formData.append("LastName", user?.lastName);
  formData.append("MiddleName", user?.middleName);
  formData.append("Email", user?.email);
  formData.append("Bvn", user?.bvn);
  formData.append("PhoneNumber", user?.phoneNumber);
  formData.append("PhoneModel", user?.phoneModel);
  formData.append("Password", user?.password);
  formData.append("ConfirmPassword", user?.confirmPassword);
  formData.append("DateOfBirth", user?.dateOfBirth);
  formData.append("Gender", user?.gender);
  formData.append("Location", user?.location);
  formData.append("ImagePath", "");

  formData.append("SecurityQuestion", user?.securityQuestion);
  formData.append("SecurityAnswer", user?.securityAnswer);
  formData.append("Disclaimer", `${user?.disclaimer}`);
  formData.append("ReferralCode", user?.referralCode);
  formData.append("ProfileType", `${user?.profileType}`);

  return apiCall({
    endpoint: "/auth/register/client",
    method: "POST",
    multipart: true,
    auth: false,
    body: formData,
  });
};

export const uploadUserFile = (userId: string, file: any, action: string) => {
  const formData = new FormData();

  formData.append("userId", userId);
  formData.append("file", file);

  return apiCall({
    endpoint: "/auth",
    param: action,
    method: "PATCH",
    multipart: true,
    auth: false,
    body: formData,
  });
};

export const generateResetToken = (username: string) => {
  return apiCall({
    endpoint: "/auth/forgot_password/generate_token",
    method: "POST",
    body: { username },
  });
};

export const confirmResetToken = (email: string, token: string) => {
  return apiCall({
    endpoint: "/auth/forgot_password/confirm_token",
    method: "POST",
    body: { email, token },
  });
};

export const resetPassword = (
  username: string,
  password: string,
  confirmPassword: string,
) => {
  return apiCall({
    endpoint: "/auth/forgot_password/reset_password",
    method: "POST",
    body: { username, password, confirmPassword },
  });
};

export const upgradeAccount = (data: NationalId) => {
  return apiCall({
    endpoint: "/auth/updateBvn",
    method: "POST",
    body: { ...data },
    auth: true,
  });
};
