import { apiCall } from "./apiCall";

export const getReferralDetails = () => {
  return apiCall({
    endpoint: "/referral/getReferralDetails",
    method: "GET",
    auth: true,
  });
};

export const getReferralHistory = () => {
  return apiCall({
    endpoint: "/referral/getReferralHistory",
    method: "GET",
    auth: true,
  });
};

export const updateReferralCode = (referralCode: string) => {
  return apiCall({
    endpoint: "/referral/updateReferralCode",
    method: "POST",
    body: { referralCode },
    auth: true,
  });
};
