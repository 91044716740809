import { useEffect, useRef } from "react";
import useUserStore from "core/services/stores/useUserStore";
import { notify } from "./notify";

interface Props {
  timeout: number;
  onInactive: () => void;
}

const useIdleTimer = ({ timeout, onInactive }: Props) => {
  const token = useUserStore((store) => store.token);
  const lastActiveTime = useRef<number>(Date.now());

  useEffect(() => {
    const handleUserInteraction = () => {
      lastActiveTime.current = Date.now();
    };

    const events = ["click", "mousemove", "keydown"];
    events.forEach((event) =>
      window.addEventListener(event, handleUserInteraction),
    );

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleUserInteraction),
      );
    };
  }, []);

  useEffect(() => {
    const handleInactivity = () => {
      const timeSinceLastActivity = Date.now() - lastActiveTime.current;

      if (timeSinceLastActivity >= timeout && token) {
        onInactive();

        notify({
          message: "You have been logged out due to inactivity",
          type: "warning",
        });
      }
    };

    const intervalId = setInterval(handleInactivity, timeout);

    return () => clearInterval(intervalId);
  }, [timeout, onInactive, token]);

  return null;
};

export default useIdleTimer;
