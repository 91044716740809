import React from "react";

const FoodStuff = React.lazy(() => import("./FoodStuff"));
const FoodStuffCart = React.lazy(() => import("./FoodStuffCart"));

const foodStuffRoutes: RoutesType[] = [
  {
    name: "Food Stuff Cart",
    layout: "/portal",
    path: "food-stuff/cart",
    icon: "",
    component: <FoodStuffCart />,
  },
  {
    name: "Food Stuff",
    layout: "/portal",
    path: "food-stuff",
    icon: "",
    component: <FoodStuff />,
  },
];

export default foodStuffRoutes;
