import { apiCall } from "./apiCall";

export const getAllOrder = (query: OrderQuery) => {
  return apiCall({
    endpoint: "/marketplace/order/history",
    pQuery: { ...query },
    method: "GET",
    auth: true,
  });
};

export const getOrderById = (orderId: string) => {
  return apiCall({
    endpoint: "/marketplace/order/details",
    param: orderId,
    method: "GET",
    auth: true,
  });
};

export const getAllNotifications = (query: NotificationQuery) => {
  return apiCall({
    endpoint: "/notification/getAllNotifications",
    pQuery: { ...query },
    method: "GET",
    auth: true,
  });
};

export const getNotificationById = (notificationId: string) => {
  return apiCall({
    endpoint: "/notification/getNotificationById",
    param: notificationId,
    method: "GET",
    auth: true,
  });
};

export const markAllNotificationAsRead = () => {
  return apiCall({
    endpoint: "/notification/markAllNotificationsAsRead",
    method: "PATCH",
    auth: true,
  });
};

export const updateNotificationAsRead = (id: string) => {
  return apiCall({
    endpoint: "/notification/update/read",
    param: id,
    method: "PATCH",
    auth: true,
  });
};

export const deleteNotification = (id: string) => {
  return apiCall({
    endpoint: "/notification/delete",
    param: id,
    method: "DELETE",
    auth: true,
  });
};
