import { formatCurrency } from "core/helpers/generalHelpers";
import React from "react";

interface Props {
  value: string;
  setValue: any;
  cashbackBalance: string | number;
}

function CashbackField({
  value,
  setValue = () => {},
  cashbackBalance = 0,
}: Props) {
  return (
    <main className="mx-auto w-11/12 md:w-4/5">
      <fieldset className="mt-5 flex items-center justify-start gap-2">
        <input
          type="checkbox"
          readOnly
          className="h-[22px] w-[22px] !accent-purple hover:cursor-none focus:accent-purple"
          checked={value === "true"}
          onChange={(e) => {
            setValue(`${e?.target?.checked}`);
          }}
        />
        <p className="font-light text-gray">
          Use cashback balance: {formatCurrency(+cashbackBalance)}
        </p>
      </fieldset>
    </main>
  );
}

export default CashbackField;
